const string = {
  // End User Dashbord

  // navEUD
  navmenu: [
    { title: "Home", id: "#home" },
    { title: "Projects", id: "#projects" },
    { title: "About Us", id: "#about" },
    { title: "Contact Us", id: "#contact" },
  ],
  navbtn: "Schedule Meeting",
  herotitle1: "Find Your",
  herotitle2blue: "Dream Home",
  herotitle2: "in Saudi!",
  herodisc:
    "Unlocking the future of Home Discovery : Experience Real Estate Like Never Before",
  herosubtitle1: "To be Coming",
  herosubtitle2: "Available",
  herosubtitle3: "Rent",
  herodesigncategory: "Categories",
  herodesigncity: "City",
  herodesignbed: "Beds & Baths",
  herodesignprice: "Prices",
  herominiprice: "$299.00",
  herominidisc:
    "Description of the property will be written here Description of property",
  herominibtn: "Check Details",
  propertysectiontitle: "Properties by",
  propertysectiontitleblue: "Cities",
  propertydisc:
    "Highlight the best of your properties by selecting the area needed to shrink down your results",
  proarray: ["Riyadh", "Makkah", "Dammam", "Tabouk", "Jeddah"],
  allarray: [
    "All",
    "Projects",
    "Villa",
    "Condos",
    "Apartment",
    "Serviced Apartment",
    "Offices",
  ],
  allcommonname: "Category",
  allcommonnamevilla: "Villa",
  allcommonnamemakkah: "Makkah",
  allcommonnamecondos: "Condos",
  allcommonnameapt: "Apartment",
  allcommontitle: "Title",
  allCommonPrice: "1,650,000 SAR",
  allCommon360btn: "360",
  allcommondisc:
    "Description of the property will be written here Description of property",
  allcommonbtn: "Step Inside",
  commonlocation: "Riyadh - Irqah",
  commonlocation2: "Riyadh - Al Jabiriyah",
  commonlocation4: "Riyadh - Al Aiss",
  locationtitle1: "Find nearby",
  locationblue: "locations",
  locationtitle2: "to order from",
  locationsearchph: "Find a store near you",
  locationsearchbtn: "Go",
  cards: [
    {
      name: "Riyadh - Al Awali",
      address: "Alawali, West Riyadh, Riyadh, Riyadh Region",
    },
    {
      name: "Al Narjis, North Riyadh",
      address: "Al Narjis, North Riyadh, Riyadh, Riyadh Region",
    },
    {
      name: "Tuwaiq neighborhood",
      address: "Tuwaiq, West Riyadh, Riyadh, Riyadh Region",
    },
  ],
  locationname: "Pokebar harbor Islands",
  locationaddress:
    "Boston Harbor, 15 State Street Suite 1100, Boston 1 km / 20 min",
  locationbtn: "Select",
  whytitle: "Why Choose Us",
  whydisc: "Highlight the best of your properties ny selecting the area",
  whydelivery: "Expertise and Experience:",
  whydeliverydisc:
    "With years of experience in the real estate industry, our knowledgeable team offers unparalleled expertise, ensuring that every project is handled with the utmost professionalism and skill.",
  whycustomer: "Quality Properties:",
  whycustomerdisc:
    "We pride ourselves on delivering high-quality properties, meticulously constructed to meet the highest standards. Our commitment to excellence guarantees that you receive a home that is both beautiful and durable.",
  whypayment: "Customer Satisfaction:",
  whypaymentdisc:
    "Our priority is your satisfaction. We provide exceptional customer service, ensuring that your needs are met and your expectations are exceeded throughout the entire buying process.",
  contacttitle: "Get in touch with us anytime!",
  contactdisc:
    "You can always send us a email or message. We will be happy to help you out.",
  contactmail: "Shoot us an email",
  contactmailid: "yourcompany@gmail.com",
  contactcall: "We’re a call away",
  contactcallmo: "+1 (123) 456 - 789",
  contactaddress: "Address",
  contactaddresstext: "Our address here",
  contactinputname: "Full Name",
  contactinputmail: "Email ID",
  contactinputmessage: "Enter your message here",
  contactmessagebtn: "Send My Message",

  // product detail
  pdknow: "Know more about the",
  pdknowspan: "Property",
  pdknowdisc:
    "Short description here Short description here Short description here Short description here Short description here Short description here Short description here",
  pdph: "Enter Email",
  pdstartbtn: "Get Started",
  pdround: "Product Details",
  pdtitle: "Luxury 3 Rooms in Riyadh Aaa",
  pdcode: "Code: 78205",
  pdpriceblue: "SAR 450,000.00",
  pdtry: "Try Using",
  pdvr: "VR",
  pd360: "360",
  pdmoreinfo: "More Info",
  pddelivery: "Delivery",
  pdpayment: "Payment",
  pdwarranty: "Warranty",
  pdcare: "Care",

  mayliketitle: "You may also like",
  mlsee: "See all",
  mlbtn: "Step Inside",
  mlbtn2: "COMPUND, CITY",

  // analytics
  analyticstitle: "Analytics",
  analyticsdisc:
    "Read our terms below to learn more about your rights and responsibilities",
  analyticsdatatitle: "Analytics of the Data",
  analyticsdatadisc:
    "Highlight the best of your properties ny selecting the area needed to shrink down your results",

  // footer
  footercname: "360 Aqar",
  footercdisc: "Description here Description here Description ",
  footerqlink: "Quick Links",
  footerqlinkarray: [
    "Home",
    "Products",
    "About Us",
    "Privacy Policy",
    "Terms & Conditions",
  ],
  footerupdate: "Want Daily Updates?",
  footerupdatedisc:
    "Subscribe to our newsletter to get every updates about your favourite company.",
  footerupdatemailph: "Enter Email",
  footerupdatemailbtn: "Join",
  footercopy1: "© Copyright 2024 - ",
  footercopy2: ", All Rights Reserved.",
  url: "360Aqar",

  taglinedisc:
    "We bring you premium real estate solutions with unmatched expertise, high-quality properties, and a commitment to your satisfaction.",
};

export default string;
