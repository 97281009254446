// IFramePage.js
import React, { useEffect } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const IFramePage = () => {
    const navigate = useNavigate(); // Initialize the navigate function

    useEffect(() => {


        // Attach the click event listener to the document
        // document.addEventListener('click', handleClickOutside);

        // Cleanup function to remove the event listener on component unmount
        // return () => {
        //     document.removeEventListener('click', handleClickOutside);
        // };
    }, [navigate]);
    const handleClickOutside = (event) => {
        const iframeElement = document.getElementById('my-iframe'); // Get the iframe element
        // Check if the click was outside the iframe
        if (iframeElement && !iframeElement.contains(event.target)) {
            navigate(-1); // Navigate back to the previous page
        }
    };

    return (
        <div className="flex flex-col gap-12 justify-center items-center pt-20">
            <iframe
                id="my-iframe" // Add an ID to the iframe for reference
                width="853"
                height="480"
                src="https://my.matterport.com/show/?m=s1DAKYjp5Qn"
                frameBorder="0" // Use camelCase for JSX
                allowFullScreen // Use camelCase for JSX
                allow="xr-spatial-tracking"
            ></iframe>

            <div>
                <button onClick={handleClickOutside} className='border border-black bg-black rounded-full flex items-center  justify-center text-2xl font-semibold'>
                    <div className='bg-black rounded-full p-2'>
                        <IoMdArrowRoundBack fill='white' />
                    </div>
                    <div className='p-1 pr-3 flex items-center text-white'>
                        Back
                    </div>
                </button>
            </div>
        </div>
    );
};

export default IFramePage;
