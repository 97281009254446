import React, { useState } from 'react'
import contact from '../asset/contactbg.png'
import string from '../string'
import mail from '../asset/mail.png'
import call from '../asset/call.png'
import location from '../asset/location.png'

const contactarray = [
    { image: mail, title: string.contactmail, para: string.contactmailid },
    { image: call, title: string.contactcall, para: string.contactcallmo },
    { image: location, title: string.contactaddress, para: string.contactaddresstext },
]

const Contact = () => {
    const [focusedInput, setFocusedInput] = useState(null);

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleBlur = () => {
        setFocusedInput(null);
    };
    return (
        <div className="contact flex justify-center ">
            {/* <div className='absolute  top-0 left-0 bottom-0 right-0 -z-10 '></div> */}
            <div className="container flex flex-col sm:flex-row py-12 px-8">
                <div className="content w-full sm:w-[50%] pr-20">
                    <div className="heading flex flex-col gap-4 pb-4">
                        <h2 className='font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl text-white'>{string.contacttitle}</h2>
                        <p className='text-sm lg:text-base text-white'>{string.contactdisc}</p>
                    </div>
                    <div className='pt-8 flex flex-col gap-2'>
                        {contactarray.map((item, index) => (
                            <div key={index} className='flex gap-2 text-white'>
                                <div>
                                    <img src={item.image} alt="" />
                                </div>
                                <div>
                                    <p className='text-base'>{item.title}</p>
                                    <p className='text-sm'>{item.para}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="form mt-8 sm:mt-0 w-full sm:w-[50%] flex justify-center sm:justify-end text-right">
                    <div className='w-full sm:w-[70%] justify-between rounded-2xl bg-black/60 p-4 flex flex-col gap-4'>
                        <div className='flex flex-col gap-4'>
                            <div className={`border-[1px]  rounded-full ${focusedInput === 'name' ? 'input-focused' : 'border-white'} text-left`}>
                                <input
                                    type="text"
                                    placeholder={string.contactinputname}
                                    className='bg-transparent py-2 px-6 rounded-full text-left outline-none text-white'
                                    onFocus={() => handleFocus('name')}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className={`border-[1px]  rounded-full ${focusedInput === 'email' ? 'input-focused' : 'border-white'} text-left`}>
                                <input
                                    type="mail"
                                    placeholder={string.contactinputmail}
                                    className='bg-transparent outline-none text-white py-2 px-6 rounded-full'
                                    onFocus={() => handleFocus('email')}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className={`border-[1px]  rounded-2xl ${focusedInput === 'message' ? 'input-focused' : 'border-white'} text-left`}>
                                <textarea
                                    placeholder={string.contactinputmessage}
                                    className='bg-transparent w-full outline-none text-white py-2 px-6 rounded-full'
                                    onFocus={() => handleFocus('message')}
                                    onBlur={handleBlur}
                                ></textarea>
                            </div>
                        </div>
                        <div>
                            <button className='bg-btnbg text-white w-full rounded-full py-2'>{string.contactmessagebtn}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact