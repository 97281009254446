import React, { useEffect, useRef, useState } from "react";
import string from "../string";
import bell from "../asset/notification.png";
import logo from "../asset/Logos/Pngs/Final-06.png";
import LanguageDropdown from "./Language";
import { useScrollOrNavigateToAllCommon } from "../utils";

const Navbar = ({ scrollToAllCommon }) => {
  const navigateToAllCommon = useScrollOrNavigateToAllCommon();

  const [isOpen, setIsOpen] = useState(false);

  const togglemenu = () => {
    setIsOpen(!isOpen);
  };
  const closemenu = () => {
    setIsOpen(false);
  };
  const menuref = useRef(null);
  useEffect(() => {
    const handleclickoutside = (e) => {
      if (menuref.current && !menuref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleclickoutside);
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.removeEventListener("mousedown", handleclickoutside);
      document.body.classList.remove("overflow-hidden");
    };
  }, [menuref, isOpen]);

  const navLinks = [
    { title: "Home", href: "/" },
    { title: "Projects", href: "#projects", onclick: scrollToAllCommon },
    { title: "About Us", href: "#demo" },
    { title: "Contact Us", href: "#contact" },
  ];

  return (
    <div className="flex justify-center sticky top-0 z-10 bg-white shadow-xl ">
      <div className="container w-full flex justify-between items-center py-4 px-8 gap-4 lg:gap-0">
        <div
          className={`${isOpen
            ? "hidden"
            : "hidden md:flex justify-between items-center w-full"
            }`}
        >
          <div className="logo font-bold lg:pt-2 xl:pt-4">
            <a href="/">
              <svg
                class=""
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 112 16"
                width="112"
                height="16"
              >
                <path d="M53,.457,45,11.314,37,.457V15h2V6.543l6,8.143,6-8.143V15h2ZM60,15H58V1h6.5a4.5,4.5,0,0,1,0,9H60Zm0-7h4.5a2.5,2.5,0,0,0,0-5H60Zm22.863,7h2.275L77.5.9,69.863,15h2.275l1.625-3h7.475Zm-8.018-5L77.5,5.1,80.155,10ZM97,11.085c0,2.371-2.175,4.16-5.06,4.16a6.494,6.494,0,0,1-4.878-2.355l1.41-1.375A4.494,4.494,0,0,0,91.94,13.29c1.8,0,3.06-.906,3.06-2.2,0-1.11-.756-1.856-2.31-2.283L91,8.42c-3.6-.884-3.6-3.043-3.6-3.753,0-2.232,1.8-3.732,4.485-3.732a6.1,6.1,0,0,1,4.581,2.05l-1.41,1.378a4.629,4.629,0,0,0-3.171-1.472c-1.579,0-2.485.647-2.485,1.777,0,.337.128,1.462,1.773,1.816l1.533.345C95.516,7.487,97,8.96,97,11.085Zm14,0c0,2.371-2.175,4.16-5.06,4.16a6.494,6.494,0,0,1-4.878-2.355l1.41-1.375a4.494,4.494,0,0,0,3.468,1.775c1.8,0,3.06-.906,3.06-2.2,0-1.11-.756-1.856-2.31-2.283L105,8.42c-3.6-.884-3.6-3.043-3.6-3.753,0-2.232,1.8-3.732,4.485-3.732a6.1,6.1,0,0,1,4.581,2.05l-1.41,1.378a4.629,4.629,0,0,0-3.171-1.472c-1.579,0-2.485.647-2.485,1.777,0,.337.128,1.462,1.773,1.816l1.533.345C109.516,7.487,111,8.96,111,11.085Zm-98.611.8h0a5.5,5.5,0,1,1,0-7.778h0l.354.354L14.157,3.05,13.8,2.7h0a7.5,7.5,0,1,0,0,10.607l0,0h0l.354-.353-1.414-1.415ZM25.5.5A7.5,7.5,0,1,0,33,8,7.5,7.5,0,0,0,25.5.5Zm0,13A5.5,5.5,0,1,1,31,8,5.5,5.5,0,0,1,25.5,13.5Zm3.207-7.293L27.293,4.793l-5,5,1.414,1.414Z"></path>
              </svg>
            </a>
          </div>
          <ul className="flex justify-between items-center  gap-2 md:gap-4 lg:gap-8 font-bold text-xs lg:text-base 2xl:text-lg">
            {navLinks.map((item, index) => (
              <li className="" key={index}>
                <a href={item.href} onClick={item.onclick}>
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          <div className="flex items-center gap-2">
            <div>
              <LanguageDropdown />
            </div>
            <div className="">
              <a
                href="https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07"
                target="_blank"
              >
                <button className="py-1 lg:py-2 px-2 lg:px-4 bg-btnbg text-white rounded-lg font-semibold ">
                  {string.navbtn}
                </button>
              </a>
            </div>
          </div>
        </div>
        <div
          className={`${isOpen
            ? "block mt-[20%] space-y-2 w-full text-center"
            : "md:hidden w-full text-right flex justify-between items-start"
            }`}
        >
          <div
            className={`w-full justify-between flex  ${isOpen ? "mb-10 flex-row items-start" : "flex-row items-start "
              }`}
          >
            <div className="logo font-bold pt-1 w-[15%] lg:pt-2 xl:pt-4">
              {/* <img src={logo} alt="" /> */}
              <svg
                class=""
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 112 16"
                width="112"
                height="16"
              >
                <path d="M53,.457,45,11.314,37,.457V15h2V6.543l6,8.143,6-8.143V15h2ZM60,15H58V1h6.5a4.5,4.5,0,0,1,0,9H60Zm0-7h4.5a2.5,2.5,0,0,0,0-5H60Zm22.863,7h2.275L77.5.9,69.863,15h2.275l1.625-3h7.475Zm-8.018-5L77.5,5.1,80.155,10ZM97,11.085c0,2.371-2.175,4.16-5.06,4.16a6.494,6.494,0,0,1-4.878-2.355l1.41-1.375A4.494,4.494,0,0,0,91.94,13.29c1.8,0,3.06-.906,3.06-2.2,0-1.11-.756-1.856-2.31-2.283L91,8.42c-3.6-.884-3.6-3.043-3.6-3.753,0-2.232,1.8-3.732,4.485-3.732a6.1,6.1,0,0,1,4.581,2.05l-1.41,1.378a4.629,4.629,0,0,0-3.171-1.472c-1.579,0-2.485.647-2.485,1.777,0,.337.128,1.462,1.773,1.816l1.533.345C95.516,7.487,97,8.96,97,11.085Zm14,0c0,2.371-2.175,4.16-5.06,4.16a6.494,6.494,0,0,1-4.878-2.355l1.41-1.375a4.494,4.494,0,0,0,3.468,1.775c1.8,0,3.06-.906,3.06-2.2,0-1.11-.756-1.856-2.31-2.283L105,8.42c-3.6-.884-3.6-3.043-3.6-3.753,0-2.232,1.8-3.732,4.485-3.732a6.1,6.1,0,0,1,4.581,2.05l-1.41,1.378a4.629,4.629,0,0,0-3.171-1.472c-1.579,0-2.485.647-2.485,1.777,0,.337.128,1.462,1.773,1.816l1.533.345C109.516,7.487,111,8.96,111,11.085Zm-98.611.8h0a5.5,5.5,0,1,1,0-7.778h0l.354.354L14.157,3.05,13.8,2.7h0a7.5,7.5,0,1,0,0,10.607l0,0h0l.354-.353-1.414-1.415ZM25.5.5A7.5,7.5,0,1,0,33,8,7.5,7.5,0,0,0,25.5.5Zm0,13A5.5,5.5,0,1,1,31,8,5.5,5.5,0,0,1,25.5,13.5Zm3.207-7.293L27.293,4.793l-5,5,1.414,1.414Z"></path>
              </svg>
            </div>
            <button
              onClick={togglemenu}
              className={`z-10 focus:outline-none md:hidden ${isOpen ? "pt-1" : ""
                }`}
            >
              <svg
                className="h-7 w-7 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                {isOpen ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 10.586L16.95 5.636l1.414 1.414L13.414 12l4.95 4.95-1.414 1.414L12 13.414l-4.95 4.95-1.414-1.414L10.586 12 5.636 7.05l1.414-1.414L12 10.586z"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 5h16v2H4V5zm0 6h16v2H4v-2zm0 6h16v2H4v-2z"
                  />
                )}
              </svg>
            </button>
          </div>

          {isOpen && (
            <div ref={menuref} className="bg-white w-full rounded-lg shadow-lg z-50">
              <ul
                className="flex flex-col justify-center items-center space-y-2 py-4 text-sm font-semibold"
              >
                {navLinks.map((item, index) => (
                  <li className="" key={index}>
                    <a href={item.href} onClick={item.onclick}>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
              <div className="flex justify-center items-center gap-2 pt-3 pb-6">
                <div>
                  <LanguageDropdown />
                </div>
                <div className="">
                  <a
                    href="https://calendly.com/info-vrvision/30min?preview_source=et_card&month=2024-07"
                    target="_blank"
                  >
                    <button className="py-1 lg:py-2 px-2 lg:px-4 bg-btnbg text-white rounded-lg font-semibold ">
                      {string.navbtn}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
