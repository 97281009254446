import React from "react";
import maylike1 from "../asset/maylike1.png";
import maylike2 from "../asset/maylike2.png";
import maylike3 from "../asset/maylike3.png";
import maylike4 from "../asset/all4.png";
import logo360 from '../asset/360 (1).png'
import like from "../asset/like.png";
import string from "../string";
import { FiShare2 } from "react-icons/fi";
import { TbOvalVertical } from "react-icons/tb";
import { SlLocationPin } from "react-icons/sl";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const may = [maylike1, maylike2, maylike3];

const Maylike = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/iframe'); // Navigate to the iframe page
  };
  return (
    <div className="flex justify-center">
      <div className="container px-4  ">
        <div className="heading w-full px-4 pt-8 flex gap-4 flex-col sm:flex-row justify-between  items-center">
          {/* <h2 className='font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
                        {string.mayliketitle}
                    </h2> */}
          <button className="underline text-greytext">{string.mlsee}</button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3" >
          {may.map((i) => (
            <div key={i} className="bg-white p-4 shadow-md relative">
              <Slider {...settings}>
                <div>
                  <img src={i} alt="" className="w-full   mb-4" />
                </div>
                <div>
                  <img src={i} alt="" className="w-full   mb-4" />
                </div>
                <div>
                  <img src={i} alt="" className="w-full   mb-4" />
                </div>
                <div>
                  <img src={i} alt="" className="w-full   mb-4" />
                </div>
              </Slider>
              <div className="absolute top-8 px-2 pr-10 flex justify-between flex-row-reverse w-full gap-1">
                <img src={like} alt="" className="w-7" />
                <button className="flex items-center bg-white text-yellow-700 p-1 rounded-md text-sm">
                  <FiShare2 />
                  SHARE
                </button>
              </div>
              <div className="flex justify-between">
                <div>
                  <div className="text-sm text-greytext">
                    {string.allcommonname}
                  </div>
                  <div className="text-lg font-bold">
                    {string.allcommontitle}
                  </div>
                  <div className="text-lg">{string.allCommonPrice}</div>
                </div>
                <div className="text-lg md:text-xl">
                  <button onClick={handleButtonClick} className="flex gap-1 items-center rounded-xl border-[1px] border-gray-300 px-4 py-2 text-black">
                    {/* <div className="p-2 rounded-xl border-[1px] border-gray-300"> */}
                    {/* <TbOvalVertical color="purple" /> */}
                    <img src={logo360} alt="" className="w-8" />
                    {/* </div> */}
                    <div>{string.allCommon360btn}</div>
                  </button>
                </div>
              </div>
              <div className=" text-greytext  text-lg">
                <div className="grid grid-cols-2 pt-2 items-center gap-4">
                  <div className="col-span-2 flex items-center gap-4">
                    <span class="material-symbols-outlined">filter_none</span>
                    <div>
                      Gross Floor Area{" "}
                      <span className="font-bold">408 sqm</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <span class="material-symbols-outlined">
                      bedroom_parent
                    </span>
                    <div>
                      Bedrooms <span>5</span>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <span class="material-symbols-outlined">bathtub</span>
                    <div>
                      Bathrooms <span>7</span>
                    </div>
                  </div>
                </div>
                <div className="font-semibold py-4">AMENITIES</div>
                <div className="grid grid-cols-2 gap-2">
                  <div className="flex gap-4 items-center">
                    <span class="material-symbols-outlined">
                      manage_accounts
                    </span>
                    <div>Maid Room</div>
                  </div>
                  <div className="flex gap-4 items-center">
                    <span class="material-symbols-outlined">id_card</span>
                    <div>Driver Room</div>
                  </div>
                  <div className="flex gap-4 items-center">
                    <span class="material-symbols-outlined">deck</span>
                    <div>Roof Terrace</div>
                  </div>
                  <div className="flex gap-4 items-center">
                    <span class="material-symbols-outlined">
                      local_laundry_service
                    </span>
                    <div>Laundry Area</div>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2 justify-between">
                <button className="mt-4 bg-btnbg text-white px-4 py-2 rounded-lg">
                  {string.mlbtn}
                </button>
                <button className="flex items-center gap-1 justify-center mt-4">
                  <div>
                    <SlLocationPin color="" size={20} />
                  </div>
                  <div className="text-purple-600">{string.mlbtn2}</div>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Maylike;
