import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import riyadh from "../asset/riyadh.png";
import makkah from "../asset/makkah.png";
import dammam from "../asset/dammam.png";
import tabouk from "../asset/tabouk.png";
import jeddah from "../asset/jeddah.png";
import string from "../string";

const Property = ({ scrollToAllCommon }) => {
  const array = [
    { image: riyadh, name: "Riyadh" },
    { image: makkah, name: "Makkah" },
    { image: dammam, name: "Dammam" },
    { image: tabouk, name: "Tabouk" },
    { image: jeddah, name: "Jeddah" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const [isDragging, setIsDragging] = useState(false);
  const clickThreshold = 5; // Allowable mouse movement in pixels

  const handleMouseDown = () => {
    setIsDragging(false);
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  const handleMouseUp = (e) => {
    if (!isDragging) {
      scrollToAllCommon();
    }
  };

  return (
    <div id="#projects" className="property flex justify-center py-4">
      <div className="container flex flex-col justify-center py-8">
        <div className="heading py-12 flex gap-4 flex-col justify-center items-center px-[1rem] md:px-[10rem] lg:px-[15rem] xl:px-[22rem]">
          <h2 className=" font-bold text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            {string.propertysectiontitle}&nbsp;
            <span className="text-btnbg">
              {string.propertysectiontitleblue}
            </span>
          </h2>
          <p className="font-manrope text-sm md:text-base xl:text-lg text-greytext text-center">
            {string.propertydisc}
          </p>
        </div>
        <Slider {...settings} className="px-8 sm:px-0 lg:px-4">
          {array.map((item, index) => (
            <div key={index} className="px-4"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              <img
                src={item.image}
                // onClick={scrollToAllCommon}
                alt=""
                className="drop-shadow-xl w-full h-auto"
              />
              <p className="text-center font-semibold">{item.name}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Property;
