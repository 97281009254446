import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Pages/Navbar";
import Home from "./Pages/Home";
import { Route, Router, Routes } from "react-router-dom";
import Footer from "./Pages/Footer";
import Product1 from "./Pages/Product1";
import Analytics from "./Pages/Analytics";
import ScrollToTop from "./Pages/ScrollToTop";
import Agreement from "./Pages/Agreement";
import whatsappAvatar from "./asset/admin.png";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import AllCommon from "./Pages/AllCommon";
import Hero from "./Pages/Hero";
import Property from "./Pages/Property";
import Location from "./Pages/Location";
import Why from "./Pages/Why";
import Contact from "./Pages/Contact";
import IFramePage from "./Pages/IFramePage";


const msg =
  "Welcome to 360aqar," +
  "\n" +
  "Your premier destination for real estate solutions!";

function App() {
  return (
    <>
      <div>
        <ScrollToTop>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/hero" element={<Hero />} />
            <Route path="/property" element={<Property />} />
            <Route path="/allCommon" element={<AllCommon />} />
            <Route path="/location" element={<Location />} />
            <Route path="/why" element={<Why />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/projects" element={<AllCommon />} />
            <Route path="/iframe" element={<IFramePage />} />
            <Route path="/product1" element={<Product1 />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </div>
    </>
  );
}

export default App;
