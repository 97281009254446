import React, { useState, useEffect } from "react";
import all1 from "../asset/all1.png";
import all2 from "../asset/all2.png";
import all3 from "../asset/all3.png";
import all4 from "../asset/all4.png";
import like from "../asset/like.png";
import shareblack from "../asset/shareblack.png";
import menu from "../asset/menu.png";
import string from "../string";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { MdLocationPin } from "react-icons/md";

const categories = [
  "All",
  "Projects",
  "Villa",
  "Condos",
  "Apartment",
  "Serviced Apartment",
  "Offices",
];

const dummyData = {
  All: [
    {
      img: all1,
      name: string.allcommonnamevilla,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation,
    },
    {
      img: all2,
      name: string.allcommonnamecondos,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation4,
    },
    {
      img: all3,
      name: string.allcommonnameapt,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation,
    },
    {
      img: all4,
      name: string.allcommonnamemakkah,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation4,
    },
  ],
  Projects: [
    {
      img: all1,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation,
    },
    {
      img: all2,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation2,
    },
    {
      img: all3,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation3,
    },
    {
      img: all4,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation4,
    },
  ],
  Villa: [
    {
      img: all1,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation,
    },
    {
      img: all2,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation2,
    },
    {
      img: all3,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation3,
    },
  ],
  Condos: [
    {
      img: all1,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation,
    },
    {
      img: all2,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation2,
    },
    {
      img: all3,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation3,
    },
    {
      img: all4,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation4,
    },
  ],
  Apartment: [
    {
      img: all1,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation,
    },
    {
      img: all2,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation2,
    },
    {
      img: all3,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation3,
    },
    {
      img: all4,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation4,
    },
  ],
  "Serviced Apartment": [
    {
      img: all1,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation,
    },
    {
      img: all2,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation2,
    },
    {
      img: all3,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation3,
    },
  ],
  Offices: [
    {
      img: all1,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation,
    },
    {
      img: all2,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation2,
    },
    {
      img: all3,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation3,
    },
    {
      img: all4,
      name: string.allcommonname,
      title: string.allcommontitle,
      desc: string.allcommondisc,
      btn: string.allcommonbtn,
      btn2: string.commonlocation4,
    },
  ],
};

const AllCommon = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [data, setData] = useState(dummyData);
  const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   // Fetch data from backend
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('YOUR_BACKEND_ENDPOINT'); // Replace with your backend endpoint
  //       const result = await response.json();
  //       if (result && Object.keys(result).length) {
  //         setData(result);
  //       } else {
  //         setData(dummyData);
  //       }
  //     } catch (error) {
  //       setData(dummyData);
  //     }
  //   };
  //   fetchData();
  // }, []);

  // const togglemenu = () => {
  //   setIsOpen(!isOpen);
  // };
  // const closemenu = () => {
  //   setIsOpen(false);
  // };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  const handleProduct1 = () => {
    navigate('/product1');
  }

  const [isDragging, setIsDragging] = useState(false);
  const clickThreshold = 5; // Allowable mouse movement in pixels

  const handleMouseDown = () => {
    setIsDragging(false);
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  const handleMouseUp = (e) => {
    if (!isDragging) {
      handleProduct1();
    }
  };

  return (
    <div id="#projects" className="w-full container mx-auto">
      <div className="hidden sm:flex justify-center space-x-4 lg:space-x-8 xl:space-x-12 2xl:space-x-16 mt-4">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`pb-2 font-semibold ${selectedCategory === category
              ? "text-btnbg border-b-4 font-semibold border-btnbg"
              : "text-greytext"
              }`}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="sm:hidden overflow-hidden w-[50%] mx-auto">
        <Slider {...settings} className="w-full">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`pb-2 ${selectedCategory === category
                ? "text-btnbg border-b-4 font-semibold border-btnbg"
                : "text-greytext"
                }`}
            >
              {category}
            </button>
          ))}
        </Slider>
      </div>

      <div className="mt-6 gap-1 overflow-hidden mx-8 sm:mx-0 lg:mx-4">
        <Slider {...settings} className="w-full">
          {(data[selectedCategory] || []).map((item, index) => (
            <div key={index}>
              <div className="bg-white p-4  relative">
                <div
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                >
                  <img src={item.img} alt="" className="w-full mb-4" />
                </div>
                <div className="absolute top-8 right-8 flex flex-col gap-1">
                  <img src={like} alt="" className="w-7" />
                  <img src={menu} alt="" className="w-7" />
                  <img src={shareblack} alt="" className="w-7" />
                </div>
                <div className="text-base text-greytext font-semibold">
                  {item.name}
                </div>
                <div className="text-lg font-bold">{item.title}</div>
                <div className="text-sm text-greytext">{item.desc}</div>
                <div className="flex justify-between gap-1 text-sm lg:text-xs xl:text-base">
                  <button className="mt-4 bg-btnbg text-white px-4 lg:px-2 py-1 rounded-lg">
                    <Link to="product1">{item.btn}</Link>
                  </button>
                  <div className="text-btnbg rounded-lg px-2 lg:px-1 mt-4 flex gap-2 lg:gap-1 justify-center items-center">
                    <MdLocationPin /> {item.btn2}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default AllCommon;
