import React, { useRef, useState } from "react";
import { TbOvalVertical } from "react-icons/tb";
import logo360 from '../asset/360 (1).png'
import { SlLocationPin } from "react-icons/sl";
import string from "../string";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom/dist";
import Slider from "react-slick";

const VerticalSlider = ({ slides }) => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/iframe'); // Navigate to the iframe page
  };

  const scrollUp = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ top: -270, behavior: "smooth" });
    }
  };

  const scrollDown = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ top: 270, behavior: "smooth" });
    }
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className=" h-full ">
      <div className="flex flex-col justify-between items-center gap-2 h-full">
        <div className="relative h-full w-full overflow-hidden">
          <div
            className="absolute inset-0 overflow-y-auto h-full"
            style={{ height: "100%" }}
            ref={sliderRef}
          >
            {slides.map((i, index) => (
              <div
                key={index}
                className="bg-white py-2  relative h-[50%]"
              >
                <div className="verticle-slider flex sm:gap-3 h-full">
                  <div className="flex items-start w-[40%]">
                    {/* <img src={i.img} alt="" className="w-full h-full" /> */}
                    <Slider {...settings} className="w-full h-full pr-1 md:pr-2 ">
                      <div className="h-full">
                        <img src={i.img} alt="" className="w-full px-1 h-full  mb-4" />
                      </div>
                      <div className="h-full">
                        <img src={i.img} alt="" className="w-full px-1 h-full  mb-4" />
                      </div>
                      <div className="h-full">
                        <img src={i.img} alt="" className="w-full px-1 h-full  mb-4" />
                      </div>
                      <div className="h-full">
                        <img src={i.img} alt="" className="w-full px-1 h-full  mb-4" />
                      </div>
                    </Slider>
                  </div>
                  <div className="w-[60%] justify-between h-full flex flex-col">
                    <div className="flex justify-between">
                      <div>
                        <div className="text-[0.70rem] text-greytext">
                          {string.allcommonname}
                        </div>
                        <div className="text-xs md:text-sm 2xl:text-base font-bold">
                          {string.allcommontitle}
                        </div>
                        <div className="text-xs md:text-sm 2xl:text-base">{string.allCommonPrice}</div>
                      </div>
                      <button
                        className=" relative  "
                        onClick={handleButtonClick} // Navigate on click
                      >
                        <div className="p-2 rounded-xl border-[1px] border-gray-300 flex gap-1 items-center">
                          <img src={logo360} alt="" className="w-4 sm:w-6 lg:w-8" />
                          <div className="text-sm md:text-sm 2xl:text-lg">{string.allCommon360btn}</div>
                        </div>
                      </button>
                    </div>

                    <div className="text-greytext flex-col flex justify-between gap-1 md:gap-2 text-xs md:text-sm 2xl:text-base">
                      <div className="grid grid-cols-2 pt-2 items-center gap-1 md:gap-2">
                        <div className="col-span-2 flex items-center gap-2">
                          <span className="material-symbols-outlined">
                            filter_none
                          </span>
                          <div>
                            Gross Floor Area{" "}
                            <span className="font-bold">408 sqm</span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="material-symbols-outlined">
                            bedroom_parent
                          </span>
                          <div>
                            Bedrooms <span>5</span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="material-symbols-outlined">
                            bathtub
                          </span>
                          <div>
                            Bathrooms <span>7</span>
                          </div>
                        </div>
                      </div>
                      <div className="font-semibold text-xs md:text-sm 2xl:text-base md:py-1">AMENITIES</div>
                      <div className="grid grid-cols-2 text-xs md:text-sm 2xl:text-base gap-1 md:gap-2">
                        <div className="flex gap-2 items-center">
                          <span className="material-symbols-outlined">
                            manage_accounts
                          </span>
                          <div>Maid Room</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <span className="material-symbols-outlined">
                            id_card
                          </span>
                          <div>Driver Room</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <span className="material-symbols-outlined">
                            deck
                          </span>
                          <div>Roof Terrace</div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <span className="material-symbols-outlined">
                            local_laundry_service
                          </span>
                          <div>Laundry Area</div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 items-center gap-1 md:gap-2  text-xs md:text-sm 2xl:text-base mt-1">
                      <button className="w-full text-xs md:text-base bg-btnbg text-white px-1 lg:px-4 py-1 rounded-md md:rounded-lg">
                        {string.mlbtn}
                      </button>
                      <button className="w-full flex text-purple-600 items-center gap-1 md:gap-2 justify-start">
                        <div>
                          <SlLocationPin color="" size={20} />
                        </div>
                        <div className=" text-xs md:text-[0.91rem] text-purple-600">{string.mlbtn2}</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="absolute -top-2 right-0 mt-2 mr-2 z-10">
            <button onClick={scrollUp} className="p-1 pt-[0.35rem] 2xl:p-2 bg-gray-200 rounded-full">
              <IoIosArrowUp />
            </button>
          </div>
          <div className="absolute -bottom-2 -right-0 mb-2 mr-2 z-10">
            <button
              onClick={scrollDown}
              className="p-1 pt-[0.35rem] 2xl:p-2 bg-gray-200 rounded-full"
            >
              <FaAngleDown />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default VerticalSlider;
