import React, { useState } from 'react'
import string from '../string'
import hero from '../asset/HeroBG.png'
import herosmall from '../asset/herosmall.png'
import share from '../asset/share.png'
import category from '../asset/category.png'
import city from '../asset/city.png'
import bed from '../asset/bed.png'
import price from '../asset/price.png'
import { CiSearch } from 'react-icons/ci'
import { FaSearch } from 'react-icons/fa'

const Hero = () => {
    const [selectedCategory, setSelectedCategory] = useState(string.herosubtitle1);
    const categories = [string.herosubtitle1, string.herosubtitle2, string.herosubtitle3];
    const renderContent = () => {
        switch (selectedCategory) {
            case string.herosubtitle1:
                return (
                    <div className="lg:p-4 flex justify-between lg:pr-12">
                        <div className="grid grid-cols-2 gap-2 lg:gap-4">
                            <div className="flex items-center">
                                <span className="mr-2"><img src={category} alt="" className='w-10' /></span>
                                <div>
                                    <p className='text-sm lg:text-base text-greytext'>{string.herodesigncategory}</p>
                                    <select className="border p-1 rounded">
                                        <option className='text-sm lg:text-base'>Select</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="mr-2"><img src={city} alt="" className='w-10' /></span>
                                <div>
                                    <p className='text-sm lg:text-base'>{string.herodesigncity}</p>
                                    <select className="border p-1 rounded">
                                        <option className=' text-xs md:text-sm lg:text-base'>Select </option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="mr-2"><img src={bed} alt="" className='w-10' /></span>
                                <div>
                                    <p className='text-sm lg:text-base'>{string.herodesignbed}</p>
                                    <select className="border p-1 rounded">
                                        <option className='text-sm lg:text-base'>Select</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="mr-2"><img src={price} alt="" className='w-10' /></span>
                                <div>
                                    <p className='text-sm lg:text-base'>{string.herodesignprice}</p>
                                    <select className="border p-1 rounded">
                                        <option className='text-sm lg:text-base'>Select</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center  rounded-lg px-4 bg-btnbg'>
                            <FaSearch className='text-white font-bold' />
                        </div>
                    </div>
                );
            case string.herosubtitle2:
                return (
                    // <div className="p-4 bg-white rounded-lg shadow">
                    //     {/* Add content related to Construction */}
                    //     <p>Construction related content</p>
                    // </div>
                    <div className="lg:p-4 flex justify-between lg:pr-12">
                        <div className="grid grid-cols-2 gap-2 lg:gap-4">
                            <div className="flex items-center">
                                <span className="mr-2"><img src={category} alt="" className='w-10' /></span>
                                <div>
                                    <p className='text-sm lg:text-base text-greytext'>{string.herodesigncategory}</p>
                                    <select className="border p-1 rounded">
                                        <option className='text-sm lg:text-base'>Select</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="mr-2"><img src={city} alt="" className='w-10' /></span>
                                <div>
                                    <p className='text-sm lg:text-base'>{string.herodesigncity}</p>
                                    <select className="border p-1 rounded">
                                        <option className=' text-xs md:text-sm lg:text-base'>Select </option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="mr-2"><img src={bed} alt="" className='w-10' /></span>
                                <div>
                                    <p className='text-sm lg:text-base'>{string.herodesignbed}</p>
                                    <select className="border p-1 rounded">
                                        <option className='text-sm lg:text-base'>Select</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <span className="mr-2"><img src={price} alt="" className='w-10' /></span>
                                <div>
                                    <p className='text-sm lg:text-base'>{string.herodesignprice}</p>
                                    <select className="border p-1 rounded">
                                        <option className='text-sm lg:text-base'>Select</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center  rounded-lg px-4 bg-btnbg'>
                            <FaSearch className='text-white font-bold' />
                        </div>
                    </div>
                );
            case string.herosubtitle3:
                return (
                    // <div className="p-4 bg-white rounded-lg shadow">
                    //     {/* Add content related to Available */}
                    //     <p>Available related content</p>
                    // </div>
                    <div className="lg:p-4 flex justify-between lg:pr-12">
                    <div className="grid grid-cols-2 gap-2 lg:gap-4">
                        <div className="flex items-center">
                            <span className="mr-2"><img src={category} alt="" className='w-10' /></span>
                            <div>
                                <p className='text-sm lg:text-base text-greytext'>{string.herodesigncategory}</p>
                                <select className="border p-1 rounded">
                                    <option className='text-sm lg:text-base'>Select</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="mr-2"><img src={city} alt="" className='w-10' /></span>
                            <div>
                                <p className='text-sm lg:text-base'>{string.herodesigncity}</p>
                                <select className="border p-1 rounded">
                                    <option className=' text-xs md:text-sm lg:text-base'>Select </option>
                                </select>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="mr-2"><img src={bed} alt="" className='w-10' /></span>
                            <div>
                                <p className='text-sm lg:text-base'>{string.herodesignbed}</p>
                                <select className="border p-1 rounded">
                                    <option className='text-sm lg:text-base'>Select</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="mr-2"><img src={price} alt="" className='w-10' /></span>
                            <div>
                                <p className='text-sm lg:text-base'>{string.herodesignprice}</p>
                                <select className="border p-1 rounded">
                                    <option className='text-sm lg:text-base'>Select</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center  rounded-lg px-4 bg-btnbg'>
                        <FaSearch className='text-white font-bold' />
                    </div>
                </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className='flex justify-center hero'>
            <div className="container flex flex-col md:flex-row py-8">
                <div className='w-full md:w-[60%] lg:w-[50%]'>
                    <div className="heading px-8">
                        <h2 className=' font-bold text-2xl md:text-3xl lg:text-4xl xl:text-5xl'>{string.herotitle1} <br />
                            <span className='text-btnbg'>{string.herotitle2blue}</span> {string.herotitle2} </h2>
                        <p className='font-manrope text-base md:text-base lg:text-lg xl:text-xl'>{string.herodisc}</p>
                    </div>
                    <div className='pb-4 sm:p-4'>
                        <div className="p-4 rounded-lg shadow">
                            <div className="flex space-x-4 lg:space-x-8 xl:space-x-12 text-lg md:text-xl">
                                {categories.map(category => (
                                    <button
                                        key={category}
                                        className={`py-2 px-4 font-semibold hover:font-semibold ${selectedCategory === category ? 'text-btnbg border-b-4 border-btnbg' : 'text-greytext'}`}
                                        onClick={() => setSelectedCategory(category)}
                                    >
                                        {category}
                                    </button>
                                ))}
                            </div>
                            <div className="mt-4">
                                {renderContent()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full px-10 md:px-0 md:w-[40%] lg:w-[50%]'>
                    <div className="image relative">
                        <img src={hero} alt="" />
                        <div className='absolute w-[60%] md:w-[55%] lg:w-[40%] right-4 lg:right-10 -bottom-14  lg:bottom-5 xl:right-16 xl:bottom-16 bg-white rounded-xl p-3 flex flex-col gap-2'>
                            <img src={herosmall} alt="" />
                            <div className='font-bold text-btnbg'>{string.herominiprice}</div>
                            <p className='text-sm'>{string.herominidisc}</p>
                            <div className='flex justify-between items-center'>
                                <button className='bg-btnbg rounded-full text-white px-1 lg:px-4 xl:px-10 py-1 lg:py-2 text-sm'>{string.herominibtn}</button>
                                <button><img src={share} alt="" className='border-btnbg border-2 px-2 lg:px-4 rounded-full py-1 sm:py-2' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero