import React from 'react'
import string from '../string'
import delivery from '../asset/delivery.png'
import customer from '../asset/customer.png'
import payment from '../asset/payment.png'

const whyarray = [
    { image: delivery, title: string.whydelivery, para: string.whydeliverydisc },
    { image: customer, title: string.whycustomer, para: string.whycustomerdisc },
    { image: payment, title: string.whypayment, para: string.whypaymentdisc },
]

const Why = () => {
    return (
        <div className="why flex justify-center py-4 px-12 font-manrope">
            <div className="container flex flex-col justify-center py-8 px-6">
                <div className="heading py-12 flex gap-4 flex-col justify-center sm:justify-start items-center">
                    <h2 className='text-center font-bold text-2xl md:text-3xl lg:text-4xl xl:text-5xl'>{string.whytitle}
                    </h2>
                    <p className='font-manrope text-base md:text-base lg:text-lg xl:text-xl text-greytext text-center'>{string.whydisc}</p>
                </div>
                <div className='flex flex-col sm:flex-row gap-3 '>
                    {whyarray.map((item, index) => (
                        <div key={index} className='flex justify-between flex-col lg:px-4 '>
                            <div className='flex justify-center pb-4'>
                                <img src={item.image} alt="" />
                            </div>
                            <div className='text-center font-semibold text-lg lg:text-xl xl:text-2xl'>{item.title}</div>
                            <div className='text-center text-greytext text-sm md:text-base lg:text-lg'>{item.para}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Why