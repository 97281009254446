import React, { useRef, useState } from "react";
import string from "../string";
import hero from "../asset/heromain.png";
import herosmall from "../asset/herosmall.png";
import share from "../asset/share.png";
import category from "../asset/category.png";
import city from "../asset/city.png";
import bed from "../asset/bed.png";
import price from "../asset/price.png";
import { CiSearch } from "react-icons/ci";
import { FaSearch } from "react-icons/fa";
import riyadh from "../asset/riyadh.png";
import makkah from "../asset/makkah.png";
import dammam from "../asset/dammam.png";
import tabouk from "../asset/tabouk.png";
import jeddah from "../asset/jeddah.png";
import Hero from "./Hero";
import AllCommon from "./AllCommon";
import Property from "./Property";
import Location from "./Location";
import Why from "./Why";
import Contact from "./Contact";
// import Property from './Property'

const Home = () => {
  const allCommonRef = useRef(null);

  const scrollToAllCommon = () => {
    if (allCommonRef.current) {
      allCommonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <Hero />
      <Property scrollToAllCommon={scrollToAllCommon} />
      <div ref={allCommonRef}>
        <AllCommon />
      </div>
      {/* <AllCommon/> */}
      <Location />
      <Why />
      <Contact />
    </>
  );
};

export default Home;
