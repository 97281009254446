import { useNavigate, useLocation } from "react-router-dom";

export const useScrollOrNavigateToAllCommon = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToAllCommon = () => {
    if (location.pathname === "/") {
      // Already on the Home page, scroll to AllCommon
      const params = new URLSearchParams(location.search);
      if (params.get("scrollTo") !== "allCommon") {
        navigate("/allCommon", { replace: true });
      }
    } else {
      // Navigate to Home page and scroll to AllCommon
      navigate("/allCommon");
    }
  };

  return navigateToAllCommon;
};
