import React from "react";
import Hero2 from "./Hero2";
import Maylike from "./Maylike";
import ProductDetail from "./ProductDetail";

const Product1 = () => {
  return (
    <>
      <Hero2 />
      {/* <ProductDetail/> */}
      <Maylike />
    </>
  );
};

export default Product1;
