import React from 'react'
import hero2 from '../asset/hero2.png'

const Hero2 = () => {
  return (
    <div className='  flex justify-center w-full'>
      <div className='container'>
        <a href="https://rivera-teal.vercel.app/">
          <img src={hero2} alt="" className='w-full' />
        </a>
      </div>
    </div>
  )
}

export default Hero2;