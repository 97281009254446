import React from 'react'
import string from '../string'
import c1 from '../asset/c1.png'
import c2 from '../asset/c2.png'
import c3 from '../asset/c3.png'
import c4 from '../asset/c4.png'

const Footer = () => {
    return (
        <div className='footer flex justify-center py-6'>
            <div className="container pt-8 px-8">
                <div className='flex flex-col sm:flex-row py-10 gap-4 sm:gap-8'>

                    <div className='px-10 sm:px-0 w-full sm:w-[30%]'>
                        <div className="w-[20%] pb-4">
                            <a href="/">
                                <svg class="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112 16" width="112" height="16">
                                    <path d="M53,.457,45,11.314,37,.457V15h2V6.543l6,8.143,6-8.143V15h2ZM60,15H58V1h6.5a4.5,4.5,0,0,1,0,9H60Zm0-7h4.5a2.5,2.5,0,0,0,0-5H60Zm22.863,7h2.275L77.5.9,69.863,15h2.275l1.625-3h7.475Zm-8.018-5L77.5,5.1,80.155,10ZM97,11.085c0,2.371-2.175,4.16-5.06,4.16a6.494,6.494,0,0,1-4.878-2.355l1.41-1.375A4.494,4.494,0,0,0,91.94,13.29c1.8,0,3.06-.906,3.06-2.2,0-1.11-.756-1.856-2.31-2.283L91,8.42c-3.6-.884-3.6-3.043-3.6-3.753,0-2.232,1.8-3.732,4.485-3.732a6.1,6.1,0,0,1,4.581,2.05l-1.41,1.378a4.629,4.629,0,0,0-3.171-1.472c-1.579,0-2.485.647-2.485,1.777,0,.337.128,1.462,1.773,1.816l1.533.345C95.516,7.487,97,8.96,97,11.085Zm14,0c0,2.371-2.175,4.16-5.06,4.16a6.494,6.494,0,0,1-4.878-2.355l1.41-1.375a4.494,4.494,0,0,0,3.468,1.775c1.8,0,3.06-.906,3.06-2.2,0-1.11-.756-1.856-2.31-2.283L105,8.42c-3.6-.884-3.6-3.043-3.6-3.753,0-2.232,1.8-3.732,4.485-3.732a6.1,6.1,0,0,1,4.581,2.05l-1.41,1.378a4.629,4.629,0,0,0-3.171-1.472c-1.579,0-2.485.647-2.485,1.777,0,.337.128,1.462,1.773,1.816l1.533.345C109.516,7.487,111,8.96,111,11.085Zm-98.611.8h0a5.5,5.5,0,1,1,0-7.778h0l.354.354L14.157,3.05,13.8,2.7h0a7.5,7.5,0,1,0,0,10.607l0,0h0l.354-.353-1.414-1.415ZM25.5.5A7.5,7.5,0,1,0,33,8,7.5,7.5,0,0,0,25.5.5Zm0,13A5.5,5.5,0,1,1,31,8,5.5,5.5,0,0,1,25.5,13.5Zm3.207-7.293L27.293,4.793l-5,5,1.414,1.414Z"></path>
                                </svg>
                            </a>
                        </div>
                        <div className=' text-greytext'>
                            {string.taglinedisc}
                        </div>
                        <div className='flex items-center gap-4 py-4'>
                            <a href="#"><img src={c3} alt="" /></a>
                            <a href="#"><img src={c2} alt="" /></a>
                            <a href="#"><img src={c4} alt="" /></a>
                        </div>
                    </div>
                    <div className='px-10  w-full sm:w-[20%]'>
                        <div className="title">
                            <h3>{string.footerqlink}</h3>
                        </div>
                        <ul>
                            {string.navmenu.map((item, index) => (
                                <li className='text-greytext' key={index} id={item.id}>{item.title}</li>
                            ))}
                            <li className="">
                                <a
                                    className='text-greytext'
                                    href="https://www.figma.com/proto/jUWMtVI5wtlSvTjMwRiPFx/Real-estate-UI-Design?node-id=140-3&t=n7oBh3lRVSKJa4ek-0&scaling=scale-down-width&content-scaling=fixed&page-id=131%3A5971&starting-point-node-id=204%3A71"
                                    target="_blank"
                                >
                                    Admin
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className='px-10 sm:px-0 w-full sm:w-[50%]'>
                        <div className="title">
                            <h3>{string.footerupdate}</h3>
                        </div>
                        <div className=' text-greytext'>{string.footerupdatedisc}</div>
                        <div className='flex gap-2 w-full py-2 md:py-4'>
                            <div className='border-[1px] w-full flex justify-between border-greytext/50 rounded-full pl-4'>
                                <input type="text" placeholder={string.footerupdatemailph} className='outline-none w-full' />
                                <button className='text-white bg-btnbg p-2 px-3 rounded-full'>{string.footerupdatemailbtn}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center text-sm'>{string.footercopy1} <a href="#" >{string.url}</a>{string.footercopy2} </div>
            </div>
        </div>
    )
}

export default Footer