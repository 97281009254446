import React, { useState } from "react";
import string from "../string";
import { CiSearch } from "react-icons/ci";
import locationmap from "../asset/locationmap.png";
import map from "../asset/map.png";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { SlLocationPin } from "react-icons/sl";
import maylike1 from "../asset/Frame1.png";
import maylike2 from "../asset/Frame2.png";
import Slider from "react-slick";
import like from "../asset/like.png";
import { FiShare2 } from "react-icons/fi";
import { TbOvalVertical } from "react-icons/tb";
import VerticalSlider from "./VerticalSlider";
const slidesData = [
  { id: 1, name: "Slide 1", img: maylike1 },
  { id: 2, name: "Slide 2", img: maylike2 },
  { id: 3, name: "Slide 3", img: maylike1 },
  { id: 4, name: "Slide 4", img: maylike2 },
];

const may = [maylike1, maylike2, maylike1, maylike2];

const Location = () => {
  const [activeButton, setActiveButton] = useState(null);
  return (
    <div className="location flex justify-center py-4 px-4 lg:px-12 font-manrope">
      <div className="container flex flex-col justify-center py-8 -mx-4">
        <div className="heading py-12 flex gap-4 flex-col sm:flex-row justify-center sm:justify-start items-center">
          <h2 className="sm:w-[60%] font-bold text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
            {string.locationtitle1}&nbsp;
            <span className="text-btnbg">{string.locationblue}</span>&nbsp;
            {string.locationtitle2}
          </h2>
          <div className="w-full sm:w-[40%] sm:flex justify-end  ">
            <div className="flex sm:w-[80%] items-center justify-between border-[1px] rounded-full pl-2">
              <CiSearch className="w-[10%]" />
              <input
                type="text"
                className="w-[75%] outline-none"
                placeholder={string.locationsearchph}
              />
              <button className="bg-btnbg p-2 rounded-full text-white W-[15%]">
                {string.locationsearchbtn}
              </button>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-6 h-[1150px] lg:h-[600px] lg:gap-2 md:gap-0 ">
          <div className="col-span-3 h-[600px] lg:h-full">
            <VerticalSlider slides={slidesData} />
          </div>
          <div className=" col-span-3 h-[500px] lg:h-full sm:px-16 lg:px-0 py-4 flex justify-center">
            <img src={map} alt="" className="h-full" />
          </div>
        </div>
        <div className="py-4 flex gap-2 justify-start items-center px-4 text-sm md:text-base lg:text-lg xl:text-xl">
          <button className="border-[1px] p-2 rounded-full border-btnbg/50">
            <GrFormPrevious className="text-btnbg" />
          </button>
          <div>1 of 5</div>
          <button className="border-[1px] p-2 rounded-full border-btnbg">
            <MdNavigateNext className="text-btnbg" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Location;
